// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Ninja Forms
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-max-type */

/* ------------------------------------------------------------------------ *\
 * Opinionated Tweaks
\* ------------------------------------------------------------------------ */

// resize h2s

.nf-field-container .user-content {
    h2 {
        font-size: remify(48, 16);
    }
}
