// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
    }
}

.header_inner {
    & {
        background: $background;
        border: 0;
        border-bottom: remify(8) solid lighten($dark, 61.1765);
        border-image: url("../media/background-static.jpg") 0 0 8 repeat;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.3) ($content-padding * 0.4));
    }
}

.header_logo {
    & {
        margin: remify(-52.5 auto 0);
        width: remify(250, 16);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        background: $background;
        border: 0;
        border-bottom: remify(8) solid lighten($dark, 61.1765);
        border-image: url("../media/background-static.jpg") 0 0 8 repeat;
        padding: remify(($content-padding * 0.3) ($content-padding * 0.4));
    }

    > .header_inner {
        background: none;
        border-bottom: 0;
        border-image: none;
        padding: 0;
    }

    > .header_inner.-fullbleed {
        max-width: none;
    }
}
