// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    @media screen and (max-width: $xxl-break) {
        font-size: 1.171303074670571vw;
    }
}

.header_logo {
    & {
        margin-top: remify(-55, 16);
    }
}
