// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget_user-content {
    /* stylelint-disable selector-max-type */

    h2 {
        font-size: remify(31, 16);
    }

    p,
    ol,
    ul,
    table {
        font-size: remify(18, 16);
    }

    /* stylelint-enable */
}

/* background variant */

.widget.-background {
    & {
        padding: remify(45 15);
    }

    .widget_content {
        max-width: remify(180);
        padding-left: remify(10);
        padding-right: remify(10);
    }
}
