// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout-block {
    & {
        padding-bottom: remify($content-padding * 0.8);
        padding-top: remify($content-padding * 0.8);
    }
}

.callout_inner {
    & {
        padding-bottom: remify($content-padding * 0.7);
        padding-top: remify($content-padding * 0.7);
    }
}

/* fullbleed variant */

.callout-block.-fullbleed {
    & {
        padding-bottom: remify($content-padding * 0.7);
        padding-top: remify($content-padding * 0.7);
    }

    > .callout_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
