// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * User Content
\* ------------------------------------------------------------------------ */

.user-content {
    /* stylelint-disable selector-no-qualifying-type, selector-max-type */

    // links

    a {
        @extend .link;
    }

    // titles

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @extend .title;
    }

    h1 em,
    h2 em,
    h3 em,
    h4 em,
    h5 em,
    h6 em {
        color: $primary_alt;
        font-family: $heading-font-alt;
        font-size: 1.15em;
        font-style: normal;
        font-weight: 400;
        text-transform: none;
    }

    h2 {
        color: $primary_alt;
        font-family: $heading-font-alt;
        font-size: remify(40, 16);
        font-style: normal;
        font-weight: 400;
        margin-bottom: remify(5, 40);
        text-transform: none;
    }

    h3 {
        align-items: center;
        display: flex;
    }

    h3::before,
    h3::after {
        background: lighten($foreground, 70.9804);
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(1);
    }

    h3::before {
        margin-right: remify(8, 22);
    }

    h3::after {
        margin-left: remify(8, 22);
    }

    h4 {
        font-size: remify(16.5, 16);
        line-height: remify(22.5, 16.5);
        margin: remify(0 0 8, 16.5);
        text-transform: none;
    }

    h5,
    h6 {
        font-size: remify(16, 16);
        line-height: remify(24, 16);
        margin: remify(0 0 8, 16);
    }

    // text

    p,
    ol,
    ul,
    table {
        @extend .text;
    }

    p .button,
    ol .button,
    ul .button,
    table .button {
        font-size: remify(14, 17);
    }

    ol,
    ul {
        padding-left: remify(18, 12);
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
        font-size: 1em;
    }

    .table_container {
        overflow: auto;
        width: 100%;
    }

    table {
        border-collapse: collapse;
        display: table;
        width: 100%;
    }

    table td,
    table th {
        border: remify(1) solid darken($background_alt, 10);
        padding: remify(6 8, 12);
        text-align: left;
        vertical-align: top;
    }

    table tbody th,
    table > tr > th,
    table[data-stacked="true"] tbody td::before {
        font-weight: 700;
        text-transform: uppercase;
    }

    table thead td,
    table thead th {
        background: $dark_alt;
        border-color: lighten($dark_alt, 10);
        color: $light;
        font-size: remify(16, 12);
        font-weight: 400;
        padding: remify(6 8, 16);
    }

    table > tr:nth-child(even) td,
    table > tr:nth-child(even) th,
    table tbody tr:nth-child(even) td,
    table tbody tr:nth-child(even) th {
        background: $background_alt;
    }

    // blockquote

    blockquote {
        font-style: italic;
        margin: remify(0 0 18, 16);
        padding: remify(0 0 0 28, 16);
        position: relative;
    }

    blockquote p,
    blockquote ol,
    blockquote ul,
    blockquote table {
        color: lighten($foreground, 50);
        font-size: remify(14, 16);
    }

    blockquote > :last-child {
        margin-bottom: 0;
    }

    // horizontal rule

    hr {
        @extend .divider;
    }

    // images

    img {
        display: block;
        height: auto !important;
        max-width: 100%;
    }

    // alignment

    .alignleft {
        float: left;
        margin-right: remify(25, 16);
        max-width: (100% / 3);
    }

    .alignright {
        float: right;
        margin-left: remify(25, 16);
        max-width: (100% / 3);
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    /* stylelint-enable */
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .user-content {
        /* stylelint-disable selector-no-qualifying-type, selector-max-type */

        h2 {
            font-size: remify(60, 16);
        }

        h3::before {
            margin-right: remify(40, 32);
        }

        h3::after {
            margin-left: remify(40, 32);
        }

        h4 {
            font-size: remify(22, 16);
        }

        h5,
        h6 {
            font-size: remify(19.2, 16);
        }

        p .button,
        ol .button,
        ul .button,
        table .button {
            font-size: remify(18, 23);
        }

        blockquote {
            margin-bottom: remify(24, 16);
            padding-left: remify(36, 16);
        }

        blockquote::before {
            font-size: remify(24, 16);
        }

        blockquote p,
        blockquote ol,
        blockquote ul,
        blockquote table {
            font-size: remify(18, 16);
        }

        /* stylelint-enable */
    }
}

/* light variant */

.user-content.-light {
    /* stylelint-disable selector-no-qualifying-type, selector-max-type */

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    table,
    ul {
        color: $light;
    }

    /* stylelint-enable */
}
