// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    @media screen and (max-width: $xxl-break) {
        font-size: 1.171303074670571vw;
    }
}
