// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Colors
\* ------------------------------------------------------------------------ */

$primary: #751113;
$primary_alt: #B7202A;

$accent: #B7202A;
$accent_alt: #DA4E5D;

$light: #FFFFFF;
$light_alt: #E5E5E5;

$dark: #000000;
$dark_alt: #808285;

$foreground: #000000;
$foreground_alt: #808285;

$background: #FFFFFF;
$background_alt: #E5E5E5;

$page_background: #E5E5E5;
$page_background_alt: #FFFFFF;

$warning: #751113;
$warning_alt: #B7202A;

/* ------------------------------------------------------------------------ *\
 * Fonts                                                                    *
\* ------------------------------------------------------------------------ */

$heading-font: "Prata", "Times New Roman", "Times", serif;
$heading-font-alt: "Arizonia", "Brush Script Std", "Caflisch", cursive;
$body-font: "Source Sans Pro", "Arial", "Helvetica", sans-serif;

/* ------------------------------------------------------------------------ *\
 * Widths                                                                   *
\* ------------------------------------------------------------------------ */

$site-width: 1500;
$column-gap: 30;
$content-padding: 50;

/* ------------------------------------------------------------------------ *\
 * Break Points                                                             *
\* ------------------------------------------------------------------------ */

$xxxl-break: 1440em / 16;
$xxl-break: 1366em / 16;
$xl-break: 1280em / 16;
$l-break: 1024em / 16;
$m-break: 960em / 16;
$s-break: 853em / 16;
$xs-break: 768em / 16;
$xxs-break: 640em / 16;
$xxxs-break: 480em / 16;

/* ------------------------------------------------------------------------ *\
 * Layers                                                                   *
\* ------------------------------------------------------------------------ */

$layers: (
    toolbar: 5,
    header: 6,
    navigation: 4,
    hero: 3,
    content: 2,
    footer: 1,
);
