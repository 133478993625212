// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Toolbar
\* ------------------------------------------------------------------------ */

.toolbar_inner {
    & {
        padding-bottom: remify($content-padding * 0.3);
        padding-top: remify($content-padding * 0.3);
    }
}

/* fullbleed variant */

.toolbar-block.-fullbleed {
    & {
        padding-bottom: remify($content-padding * 0.3);
        padding-top: remify($content-padding * 0.3);
    }

    > .toolbar_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
