// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout-block {
    & {
        @include layer("callout");

        background: url("../media/background-static.jpg") center top repeat;
        padding: remify(($content-padding * 0.4) ($content-padding * 0.3));
    }
}

.callout_inner {
    & {
        background: $background;
        border: remify(1) solid lighten($foreground, 70.9804);
        box-shadow: inset remify(0 0 0 5) $background, inset remify(0 0 0 6) lighten($foreground, 70.9804);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.5) ($content-padding * 0.4));
    }
}

/* fullbleed variant */

.callout-block.-fullbleed {
    & {
        background: $background;
        border: remify(1) solid lighten($foreground, 70.9804);
        box-shadow: inset remify(0 0 0 5) $background, inset remify(0 0 0 6) lighten($foreground, 70.9804);
        padding: remify(($content-padding * 0.5) ($content-padding * 0.4));
    }

    > .callout_inner {
        background: none;
        border: 0;
        box-shadow: none;
        padding: 0;
    }

    > .callout_inner.-fullbleed {
        max-width: none;
    }
}
