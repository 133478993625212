// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_logo {
    & {
        margin-top: remify(-65, 16);
        width: remify(310, 16);
    }
}
