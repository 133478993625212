// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer_inner {
    & {
        border-image: url("../media/background-static.jpg") 8 0 0 repeat;
        border-top-width: remify(12);
    }
}

.footer_widget {
    @media screen and (max-width: $xl-break - (1 / 16)) {
        font-size: 1.25vw;
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        border-image: url("../media/background-static.jpg") 8 0 0 repeat;
        border-top-width: remify(12);
    }

    > .footer_inner {
        border-image: none;
        border-top-width: 0;
    }
}
