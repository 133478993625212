// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header_inner {
    & {
        border-bottom-width: remify(12);
        border-image: url("../media/background-static.jpg") 0 0 12 repeat;
        padding-bottom: remify(20);
        padding-top: remify(20);
    }
}

.header_logo {
    & {
        margin-top: remify(-40, 16);
        width: remify(260, 16);
    }
}

/* fullbleed variant */

.header-block.-fullbleed {
    & {
        border-bottom-width: remify(12);
        border-image: url("../media/background-static.jpg") 0 0 12 repeat;
        padding-bottom: remify(20);
        padding-top: remify(20);
    }

    > .header_inner {
        border-bottom: 0;
        border-image: none;
        padding-bottom: 0;
        padding-top: 0;
    }
}
