// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Widget
\* ------------------------------------------------------------------------ */

.widget {
    & {
        background: $background;
        border: remify(1) solid lighten($foreground, 70.9804);
        box-shadow: inset remify(0 0 0 5) $background, inset remify(0 0 0 6) lighten($foreground, 70.9804);
        margin: remify(0 0 25);
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.5) ($content-padding * 0.4));
    }
}

.widget_user-content {
    /* stylelint-disable selector-max-type */

    h2 {
        font-size: remify(26, 16);
    }

    p,
    ol,
    ul,
    table {
        font-size: remify(15, 16);
    }

    > :last-child {
        margin-bottom: 0 !important;
    }

    /* stylelint-enable */
}

/* background variant */

.widget.-background {
    & {
        background-position: right top;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: none;
        padding: remify(40 12.5);
    }

    .widget_content {
        background: $background;
        box-shadow: remify(0 0 5) transparentize($dark, 0.65);
        max-width: remify(150);
        padding: remify(10 15);
    }
}
