// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Title
\* ------------------------------------------------------------------------ */

.title {
    & {
        color: $primary;
        font-family: $heading-font;
        font-size: remify(22, 16);
        font-weight: 700;
        letter-spacing: 0.025em;
        line-height: remify(26, 22);
        margin: remify(0 0 10, 22);
        text-transform: uppercase;
    }
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .title {
        & {
            font-size: remify(32, 16);
        }
    }
}

/* divider variant */

.title.-divider {
    & {
        align-items: center;
        display: flex;
    }

    &::before,
    &::after {
        background: lighten($foreground, 70.9804);
        content: "\0020";
        display: block;
        flex: 1 1 0%;
        height: remify(1);
    }

    &::before {
        margin-right: remify(8, 22);
    }

    &::after {
        margin-left: remify(8, 22);
    }
}

/* divider Tablet variant */

@media screen and (min-width: $xs-break) {
    .title.-divider {
        &::before {
            margin-right: remify(40, 32);
        }

        &::after {
            margin-left: remify(40, 32);
        }
    }
}

/* divider left variant */

.title.-divider.-left {
    &::after {
        display: none;
    }
}

/* divider right variant */

.title.-divider.-right {
    &::before {
        display: none;
    }
}

/* sub variant */

.title.-sub {
    & {
        font-size: remify(18, 16);
    }
}

/* sub Tablet variant */

@media screen and (min-width: $xs-break) {
    .title.-sub {
        font-size: remify(20, 16);
    }
}
