// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_sidebar {
    & {
        width: remify(350);
    }
}
