// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Button
\* ------------------------------------------------------------------------ */

.button {
    & {
        background: $background_alt;
        border: remify(1) solid lighten($foreground, 70.9804);
        color: $primary;
        cursor: pointer;
        display: inline-block;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        line-height: remify(20, 14);
        margin: remify(0 0 12.5, 14);
        outline: 0;
        padding: remify(8 16, 14);
        text-decoration: none;
        text-transform: lowercase;
        transition: background 0.15s;
    }

    &:focus,
    &:hover {
        background: lighten($background, 5);
    }
}

/* Desktop variant */

@media screen and (min-width: $xs-break) {
    .button {
        font-size: remify(18, 16);
    }
}
