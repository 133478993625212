// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.3) ($content-padding * 0.4));
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        background: $background;
        padding: remify(($content-padding * 0.3) ($content-padding * 0.4));
    }

    > .content_inner {
        background: none;
        padding: 0;
    }

    > .content_inner.-fullbleed {
        max-width: none;
    }
}

/* image variant */

.content-block.-image {
    .content_figure {
        margin: 0;
    }

    .content_image {
        display: block;
        margin: 0 auto;
        max-height: 100%;
        max-width: 100%;
    }
}
