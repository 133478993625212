// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu List
\* ------------------------------------------------------------------------ */

/* ============================ GLOBAL VARIANTS =========================== */

.menu-list_container {
    & {
        @extend %clearfix;
    }
}

.menu-list {
    & {
        @extend %clearfix;

        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        text-align: center;
    }
}

.menu-list_item {
    & {
        float: left;
        position: relative;
    }

    &.-parent {
        display: flex;
        flex-wrap: wrap;
    }

    &.-mega {
        position: static;
    }
}

.menu-list_link {
    & {
        display: block;
        flex: 1 1 0%;
        min-width: 0;
        text-decoration: none;
    }
}

.menu-list_toggle {
    & {
        background: none;
        border: 0;
        cursor: pointer;
        display: block;
        flex: 0 0 auto;
        font-size: 1em;
        margin: 0;
    }

    > .icon {
        transition: transform 0.15s;
    }

    .menu-list_item.is-active > & > .icon {
        transform: rotate(-180deg);
    }
}

/* center variant */

.menu-list.-center {
    & {
        float: right;
        position: relative;
        right: 50%;
    }

    > .menu-list_item {
        left: 50%;
    }
}

/* flex variant */

.menu-list.-flex {
    & {
        align-items: center;
        display: flex;
    }

    > .menu-list_item {
        flex: 1 1 0%;
        min-width: 0;
    }
}

/* vertical variant */

.menu-list.-vertical {
    & {
        text-align: left;
    }

    > .menu-list_item {
        float: none;
    }
}

/* vertical reverse variant */

.menu-list.-vertical.-reverse {
    & {
        text-align: right;
    }
}

/* tier variant */

.menu-list[class*="-tier"] {
    & {
        min-width: 100%;
    }
}

/* tier accordion variant */

.menu-list[class*="-tier"].-accordion {
    & {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.25s, visibility 0s 0.25s;
        visibility: hidden;
        width: 100%;
    }

    .menu-list_item.-parent.is-active > & {
        max-height: remify(500, 16);
        transition: max-height 0.25s;
        visibility: visible;
    }
}

/* tier overlay variant */

.menu-list[class*="-tier"].-overlay {
    & {
        left: -999em;
        opacity: 0;
        position: absolute;
        top: -999em;
        transition: left 0s 0.15s, opacitwy 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
        width: remify(250, 16);
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        opacity: 1;
        transition: opacity 0.15s;
        visibility: visible;
    }
}

/* tier variant */

.menu-list[class*="-tier"].-overlay.-reverse {
    & {
        transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
        transition: opacity 0.15s;
    }
}

/* tier1 overlay variant */

.menu-list.-tier1.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 0;
        top: 100%;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(-90deg);
    }
}

/* tier1 overlay reverse variant */

.menu-list.-tier1.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 0;
    }

    > .menu-list_item.is-active > .menu-list_toggle > .icon {
        transform: rotate(90deg);
    }
}

/* tier2 overlay variant */

.menu-list.-tier2.-overlay {
    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: 100%;
        top: 0;
    }
}

/* tier2 overlay reverse variant */

.menu-list.-tier2.-overlay.-reverse {
    & {
        left: auto;
        right: 999em;
    }

    .menu-list_item.-parent:hover > &,
    .menu-list_item.-parent.is-active > & {
        left: auto;
        right: 100%;
    }
}

/* ============================ CUSTOM VARIANTS =========================== */

/* mega variant */

.menu-list_container.-mega {
    & {
        display: flex;
        left: -999em;
        opacity: 0;
        position: absolute;
        right: 999em;
        top: -999em;
        transition: left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
        visibility: hidden;
    }

    .menu-list_item.-mega.-parent:hover > &,
    .menu-list_item.-mega.-parent.is-active > & {
        left: 0;
        opacity: 1;
        right: 0;
        top: 100%;
        transition: opacity 0.15s;
        visibility: visible;
    }

    .menu-list.-tier1 {
        flex: 1 1 0%;
        min-width: 0;
    }

    .menu-list.-tier2 {
        flex: 0 0 100%;
    }
}

/* meta variant */

.menu-list.-meta {
    & {
        color: $foreground_alt;
        font-family: $heading-font;
        font-size: remify(14, 16);
        font-weight: 700;
        text-transform: uppercase;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(20, 14);
    }

    .menu-list_link {
        color: $foreground_alt;
        display: inline-block;
    }

    .menu-list_link:hover {
        color: lighten($foreground_alt, 10);
    }
}

/* navigation variant */

.menu-list.-navigation {
    & {
        font-family: $heading-font;
        font-size: remify(18, 16);
        font-weight: 400;
        text-transform: uppercase;
    }

    .menu-list_link,
    .menu-list_toggle {
        color: $accent;
        padding: remify(8 18, 18);
    }

    .menu-list_link:focus,
    .menu-list_link:hover,
    > .menu-list_item.-parent:hover > .menu-list_link,
    > .menu-list_item.-parent.is-active > .menu-list_link,
    .menu-list_toggle:focus,
    .menu-list_toggle:hover,
    .menu-list_item.-parent:hover > .menu-list_toggle,
    .menu-list_item.-parent.is-active > .menu-list_toggle {
        color: $accent_alt;
    }

    // tier 1

    .menu-list.-tier1 {
        background-color: $accent;
        font-size: remify(16, 18);
    }

    .menu-list.-tier1::before,
    .menu-list.-tier1::after {
        content: "\0020";
        display: block;
        height: remify(8, 16);
        width: 100%;
    }

    .menu-list.-tier1.-mega {
        max-width: remify(250, 16);
    }

    .menu-list.-tier1.-mega:not(:last-child) {
        border-right: remify(1) solid darken($accent, 10);
    }

    .menu-list.-tier1 .menu-list_link,
    .menu-list.-tier1 .menu-list_toggle {
        color: $light;
        padding: remify(4 18, 16);
    }

    .menu-list.-tier1 .menu-list_link:focus,
    .menu-list.-tier1 .menu-list_link:hover,
    .menu-list.-tier1:not(.-mega) > .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier1:not(.-mega) > .menu-list_item.-parent.is-active > .menu-list_link,
    .menu-list.-tier1 .menu-list_toggle:focus,
    .menu-list.-tier1 .menu-list_toggle:hover,
    .menu-list.-tier1:not(.-mega) > .menu-list_item.-parent:hover > .menu-list_toggle,
    .menu-list.-tier1:not(.-mega) > .menu-list_item.-parent.is-active > .menu-list_toggle {
        background-color: lighten($accent, 10);
    }

    // tier 2

    .menu-list.-tier2 {
        font-size: remify(14, 16);
    }

    .menu-list.-tier2:not(.-mega)::before,
    .menu-list.-tier2::after {
        content: "\0020";
        display: block;
        height: remify(6, 14);
        width: 100%;
    }

    .menu-list.-tier2:not(.-mega) {
        background-color: lighten($accent, 10);
    }

    .menu-list.-tier2 .menu-list_link,
    .menu-list.-tier2 .menu-list_toggle {
        color: $light;
        padding: remify(2 18, 14);
    }

    .menu-list.-tier2 .menu-list_link:focus,
    .menu-list.-tier2 .menu-list_link:hover,
    .menu-list.-tier2:not(.-mega) > .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-tier2:not(.-mega) > .menu-list_item.-parent.is-active > .menu-list_link,
    .menu-list.-tier2 .menu-list_toggle:focus,
    .menu-list.-tier2 .menu-list_toggle:hover,
    .menu-list.-tier2:not(.-mega) > .menu-list_item.-parent:hover > .menu-list_toggle,
    .menu-list.-tier2:not(.-mega) > .menu-list_item.-parent.is-active > .menu-list_toggle {
        background-color: lighten(lighten($accent, 10), 10);
    }

    .menu-list.-tier2 .menu-list_link::before {
        content: "\002D\0020\0020";
    }
}

/* pagination variant */

.menu-list.-pagination {
    & {
        font-family: $body-font;
        font-size: remify(14, 16);
        font-weight: 400;
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(8, 14);
    }

    .menu-list_link.-current {
        color: $foreground;
        cursor: auto;
    }
}

/* sitemap variant */

.menu-list.-sitemap {
    & {
        color: lighten($foreground, 18.4314);
        font-family: $heading-font;
        font-size: remify(16, 16);
        font-weight: 400;
        line-height: remify(24, 16);
        margin: remify(0 0 8, 16);
        text-transform: lowercase;
    }

    .menu-list_item:not(:last-child)::after {
        content: "|";
        display: inline-block;
        margin: remify(0 3, 16);
    }

    .menu-list_link {
        color: inherit;
        display: inline-block;
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: inherit;
        text-decoration: underline;
    }
}

/* social variant */

.menu-list.-social {
    & {
        font-size: remify(20, 16);
        line-height: remify(32, 20);
    }

    .menu-list_item:not(:last-child) {
        margin-right: remify(20, 20);
    }

    .menu-list_link {
        background: $background;
        border: remify(1) solid currentColor;
        color: lighten($foreground, 70.9804);
        height: remify(32, 20);
        width: remify(32, 20);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: $accent;
    }
}

/* toolbar variant */

.menu-list.-toolbar {
    & {
        font-family: $heading-font;
        font-size: remify(16, 16);
        font-weight: 400;
        text-transform: lowercase;
    }

    &:not(.-vertical) .menu-list_item:not(:last-child) {
        margin-right: remify(30, 16);
    }

    .menu-list_link {
        color: lighten($foreground, 26.2745);
    }

    .menu-list_link:focus,
    .menu-list_link:hover {
        color: $accent;
    }

    &.-vertical .menu-list_link {
        padding: remify(4 18, 16);
    }
}
