// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

/* image variant */

.content-block.-image {
    & {
        padding-bottom: 0;
    }

    .content_inner {
        overflow: hidden;
        padding-bottom: remify($content-padding);
        position: relative;
    }

    .content_title {
        position: relative;
        z-index: 1;
    }

    .content_figure {
        align-items: flex-end;
        bottom: 0;
        display: flex;
        max-width: 50%;
        padding-top: remify(($content-padding / 3));
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }

    .content_image {
        flex: 0 0 auto;
    }

    .content_user-content {
        position: relative;
        z-index: 1;
    }
}
