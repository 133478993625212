// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

/* callout variant */

.article.-callout {
    .article_title {
        font-size: remify(20, 16);
    }
}

/* headline variant */

.article.-headline {
    & {
        margin-bottom: remify(20, 16);
        padding-bottom: remify(20, 16);
    }

    .article_figure {
        width: remify(90, 16);
    }

    .article_text {
        font-size: remify(18, 16);
    }
}
