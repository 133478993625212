// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content_inner {
    & {
        padding-bottom: remify($content-padding);
        padding-top: remify($content-padding);
    }
}

/* fullbleed variant */

.content-block.-fullbleed {
    & {
        padding-bottom: remify($content-padding);
        padding-top: remify($content-padding);
    }

    > .content_inner {
        padding-bottom: 0;
        padding-top: 0;
    }
}
