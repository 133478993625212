// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-max-type */

// Custom

.swiper-picture {
    & {
        border-bottom-width: remify(12);
        border-image: url("../media/background-static.jpg") 0 0 12 repeat;
    }
}

.swiper-user-content {
    h1 {
        font-size: remify(52, 16);
    }
}
