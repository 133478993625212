// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article_figure {
    & {
        border: remify(1) solid lighten($foreground, 70.9804);
        margin: remify(0 0 30, 16);
    }
}

.article_image {
    & {
        display: block;
        width: 100%;
    }
}

/* callout variant */

.article.-callout {
    & {
        background: $background;
        border: remify(1) solid lighten($foreground, 70.9804);
        margin: remify(0 0 ($content-padding * 0.5));
    }

    .article_figure {
        border: 0;
        margin: 0;
    }

    .article_content {
        padding: remify($content-padding * 0.4);
    }

    .article_link {
        color: inherit;
    }

    .article_link:focus,
    .article_link:hover {
        color: inherit;
        text-decoration: underline;
    }

    .article_title {
        font-size: remify(18, 16);
    }
}

/* callout alt variant */

.article.-callout.-alt {
    & {
        border: 0;
    }

    .article_content {
        padding-left: 0;
        padding-right: 0;
    }
}

/* excerpt variant */

.article.-excerpt {
    &:not(:last-of-type) {
        border-bottom: remify(1) solid darken($background_alt, 10);
        margin-bottom: remify(20, 16);
    }

    .article_link {
        color: $primary;
    }

    .article_link:focus,
    .article_link:hover {
        color: $primary_alt;
    }

    .article_title {
        font-size: remify(20, 16);
    }
}

/* headline variant */

.article.-headline {
    & {
        border-bottom: remify(1) solid lighten($foreground, 70.9804);
        margin: remify(0 0 15, 16);
        padding: remify(0 0 15, 16);
    }

    .article_figure {
        border: 0;
        margin: 0;
        width: remify(75, 16);
    }

    .article_text {
        font-size: remify(15, 16);
    }
}
