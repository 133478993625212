// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
    }

    @media screen and (max-width: $l-break - (1 / 16)) {
        text-align: center;
    }
}

.footer_inner {
    & {
        background: $page_background;
        border: 0;
        border-image: url("../media/background-static.jpg") 8 0 0 repeat;
        border-top: remify(8) solid lighten($dark, 61.1765);
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(($content-padding * 0.4) ($content-padding * 0.3));
    }
}

.footer_link {
    & {
        color: inherit;
    }

    &:focus,
    &:hover {
        color: inherit;
        text-decoration: underline;
    }
}

.footer_title {
    & {
        font-size: remify(18, 16);
    }
}

.footer_text {
    & {
        color: lighten($dark, 45.8824);
        font-family: $heading-font;
        font-size: remify(12, 16);
    }
}

.footer_menu-list_container {
    @media screen and (max-width: $xl-break - (1 / 16)) {
        .menu-list.-social {
            float: right;
            margin-top: remify(20, 20);
            position: relative;
            right: 50%;
        }

        .menu-list.-social .menu-list_item {
            float: left;
            left: 50%;
            position: relative;
        }
    }
}

.footer_widget {
    @media screen and (min-width: (600em / 16)) {
        .nf-row > * {
            align-items: center;
            display: flex;
        }
    }
}

/* fullbleed variant */

.footer-block.-fullbleed {
    & {
        background: $page_background;
        border: 0;
        border-image: url("../media/background-static.jpg") 8 0 0 repeat;
        border-top: remify(8) solid lighten($dark, 61.1765);
        padding: remify(($content-padding * 0.4) ($content-padding * 0.3));
    }

    > .footer_inner {
        background: none;
        border-image: none;
        border-top: 0;
        padding: 0;
    }

    > .footer_inner.-fullbleed {
        max-width: none;
    }
}
