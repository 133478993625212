// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Text
\* ------------------------------------------------------------------------ */

.text {
    & {
        color: $foreground;
        font-family: $body-font;
        font-size: remify(17, 16);
        font-weight: 300;
        line-height: remify(22, 17);
        margin: remify(0 0 22, 17);
    }
}

/* Tablet variant */

@media screen and (min-width: $xs-break) {
    .text {
        & {
            font-size: remify(23, 16);
        }
    }
}

/* label variant */

.text.-label {
    & {
        cursor: pointer;
    }
}
