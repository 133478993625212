// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

/* stylelint-disable selector-class-pattern, selector-max-type */

// Custom

.swiper-container {
    & {
        border: 0;
        border-bottom: remify(12) solid lighten($dark, 61.1765);
        border-image: url("../media/background-static.jpg") 0 0 12 repeat;
    }
}

.swiper-picture {
    & {
        border-bottom: 0;
    }
}

.swiper-image {
    & {
        max-width: remify(($site-width / 3) * 2);
        width: (100% / 3) * 2;
    }
}

.swiper-caption {
    & {
        background: none;
        bottom: 0;
        display: flex;
        left: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &::before {
        background: linear-gradient(to right, transparentize($background, 1) 0%, transparentize($background, 0) 20%);
        bottom: 0;
        content: "\0020";
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        width: (100% / 3) * 2;
        width: calc(#{(100% / 3) * 2} + #{remify($content-padding)});
        z-index: 0;
    }

    .swiper-caption-inner {
        align-items: center;
        display: flex;
        padding: remify(($content-padding * 0.3) ($content-padding * 0.4) ($content-padding * 0.3) ($site-width / 3), 16);
        position: relative;
        z-index: 1;
    }
}

.swiper-user-content {
    h1 {
        font-size: remify(52, 16);
    }
}
